body{
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

h1{
  margin: 0;
  margin-bottom: .5rem;
}

.input{
  border: 1px solid #333;
  border-radius: .3rem;
  padding: .25rem;
  width: 7rem;
}

select{
  margin-left: .5rem;
}

.equals{
  font-weight: bold;
  font-size: 2rem;
}